import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './components/navbar.component';
import { MainPage } from './Pages/main.page';

const routes: Routes = [
  // Tus rutas aquí
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: NavbarComponent,
    children: [
      { path: 'home', component: MainPage },
    ]
  },

  { path: '**', component: MainPage },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
