import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  template: `
    <div class="flex relative">
  <header class="flex w-full justify-between items-center z-30 top-0 px-4 py-1 lg:py-5 bg-blueheader h-[9vh] fixed">
    <p (click)="scrollTo('home')" class="w-36 md:w-64 lg:w-96 text-white font-bold lg:hidden">COBREGA SRL</p>

    <nav id="navigation" class="lg:flex lg:w-full lg:justify-between hidden items-center xl:flex  shadow-myorange relative transition-shadow duration-300 cursor-pointer hover:shadow-myorange">
      <p (click)="scrollTo('home')" class="w-36 mr-6 cursor-pointer text-white font-bold">COBREGA SRL</p>
      <ul class="flex space-x-10 lg:p-4 lg:gap-[2vw] text-white">
        <li *ngFor="let chimiMenuItem of chimiMenuItems" class="cursor-pointer">
          <a (click)="scrollTo(chimiMenuItem.link.substring(1))" routerLinkActive="active-link-class" class="hover:-translate-y-1 hover:text-black duration-150 font-semibold">
            {{ chimiMenuItem.page }}
          </a>
        </li>
      </ul>
    </nav>

    <!-- Icono de Menú para Móviles -->
    <i (click)="toggleMenu()" class="w-8 text-2xl fa-solid fa-bars hover:text-hardblue cursor-pointer lg:hidden"></i>
  </header>

  <!-- Menú Hamburguesa Activo -->
  <ul *ngIf="chimiMenuIsActive" class="xl:flex h-screen fixed text-white items-center space-y-6 flex flex-col inset-0 bg-black opacity-95 z-40">
    <div class="w-full flex justify-end p-6">
      <i (click)="toggleMenu()" class="w-8 text-2xl fa-solid fa-times hover:text-hardblue cursor-pointer"></i>
    </div>
    <li *ngFor="let chimiMenuItem of chimiMenuItems" class="cursor-pointer sub-title">
      <a (click)="scrollTo(chimiMenuItem.link.substring(1))" routerLinkActive="active-link-class" class="hover:-translate-y-1 hover:text-primary duration-150 font-semibold">
        {{ chimiMenuItem.page }}
      </a>
    </li>
  </ul>
  <main class="flex w-full flex-grow flex-1 overflow-y-auto overflow-x-hidden">
    <router-outlet></router-outlet>
  </main>
</div>

  `,
  styles: `

  `
})
export class NavbarComponent {
  chimiMenuIsActive: boolean = false;
  chimiMenuItems = [
    {link: '#home', page:'Home'},
    {link: '#nosotros', page:'Nosotros'},
    {link: '#servicios', page:'Servicios'},
    {link: '#testimonios', page:'Testimonios'},
    {link: '#contact', page:'Contacto'}
  ];

  toggleMenu() {
    this.chimiMenuIsActive = !this.chimiMenuIsActive;
    // console.log('Menu toggled:', this.chimiMenuIsActive);
  }

  scrollTo(elementId: string): void {
    this.chimiMenuIsActive = false;
    const element = document.getElementById(elementId);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

}
