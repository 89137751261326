import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

@Component({
  selector: 'app-main',
  template: `

    <section id="home" class="flex flex-col items-center justify-center h-[90vh] text-myorange text-center px-6 relative overflow-hidden">
      <img src="../../assets/image1.jpg" loading="lazy" class="absolute w-full h-full object-cover">
      <div class="bg-black bg-opacity-50 w-full h-full absolute z-10"></div>
      <div class="flex flex-col z-20 justify-center items-center">
        <h2 class="text-white text-5xl font-bold">COBREGA <span class="text-white font-bold">SRL</span></h2>
        <h2 class="text-white text-xl mt-4">
          <span id="typed"></span>
        </h2>
      </div>
    </section>

    <section id="nosotros" class="grid lg:grid-cols-12 items-center lg:h-screen px-5 pt-[9vh] max-w-[100vw]" >
      <div class="grid col-span-12 lg:col-span-7 space-y-5 lg:space-y-10 xl:ml-2.5">
          <h4 class="font-semibol justify-center text-gray-600 text-xl hover:-translate-y-2 transition-transform duration-200 easy-in" >Hola, somos:</h4>
          <p class="font-semibold text-5xl lg:text-[65px]">
            <span class="text-blueheader">Cobrega</span> <span class="text-tahiti"> SRL</span>
          </p>
          <p class="font-semibold leading-7 lg:w-1/2 text-justify mt-5">Una empresa que se dedica a diferentes aspectos legales;
             en nuestro Departamento de Cobransas somos la empresa líder en recaudación de valores en atraso de nuestros clientes,
             los cuales en tiempo récord logran una disminución sustancial de los montos en Mora, permitiendo de esta manera un mejor funcionamiento de las mismas,
             al servir de herramienta para el buen funcionamiento de las entidades de intermediación financiera.
          </p>
      </div>

      <div class="grid col-span-12 items-center lg:items-center lg:col-span-5 h-full p-3 lg:p-0 xl:mr-2.5">

          <div id="encaje" class="logo m-5 p-10 mt-10 border shadow-myorange ">
            <div id="logosobre" class=" img w-full relative  flex items-center justify-center p-2">
              <img class="rounded-lg object-contain lg:object-cover lg:w-full lg:h-5/6 mt-4 lg:mt-0 transition-shadow duration-300 cursor-pointer hover:shadow-xl hover:shadow-backboneblue" src="../../assets/img2.jpg" alt="...">
            </div>
          </div>

        </div>

    </section>

    <section id="servicios" class="py-12 bg-cover relative w-screen h-auto bg-blueheader pt-[9vh] sm:max-w-[100vw] xs:px-1 sm:px-0">
  <div class="container mx-auto px-4">
    <h2 class="text-4xl font-bold text-center mb-12  text-white py-2">Nuestros Servicios</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      <div class="service-card bg-white bg-opacity-90 p-8 rounded-lg shadow-md hover:shadow-lg text-center">
        <h3 class="text-2xl font-bold mb-4">Cobros</h3>
        <p class="mb-4 text-justify">Somos líderes en la recaudación de valores en atraso, logrando una rápida disminución de montos morosos para mejorar el funcionamiento financiero de nuestros clientes. Nuestros servicios son herramientas clave para las entidades de intermediación financiera.</p>
        <a href='https://wa.me/8292218497' target="_blank">
          <button class="bg-blue-500 text-white py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg">+ Info</button>
        </a>
      </div>
      <div class="service-card bg-white bg-opacity-90 p-8 rounded-lg shadow-md hover:shadow-lg text-center">
        <h3 class="text-2xl font-bold mb-4">Servicios Legales</h3>
        <p class="mb-4 text-justify">Nuestro compromiso es evidente en cada aspecto legal que manejamos. Desde la gestión inmobiliaria hasta los préstamos, nos destacamos por nuestra responsabilidad, seriedad y compromiso, brindando soluciones efectivas para todas las necesidades legales de nuestros clientes.</p>
        <a href='https://wa.me/8292218497' target="_blank">
          <button class="bg-blue-500 text-white py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg">+ Info</button>
        </a>
      </div>
      <div class="service-card bg-white bg-opacity-90 p-8 rounded-lg shadow-md hover:shadow-lg text-center">
        <h3 class="text-2xl font-bold mb-4">Préstamos</h3>
        <p class="mb-4 text-justify">Ofrecemos préstamos personales, vehiculares, hipotecarios y más con rapidez, tasas atractivas y máxima privacidad. Nuestros servicios financieros se destacan por su eficiencia y fiabilidad, brindando soluciones adaptadas a las necesidades individuales de cada cliente.</p>
        <a href='https://wa.me/8292218497' target="_blank">
          <button class="bg-blue-500 text-white py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg">+ Info</button>
        </a>
      </div>
      <div class="service-card bg-white bg-opacity-90 p-8 rounded-lg shadow-md hover:shadow-lg text-center">
        <h3 class="text-2xl font-bold mb-4">Asesoría Inmobiliaria</h3>
        <p class="mb-4 text-justify">Ofrecemos consultoría en la adquisición de inmuebles, traspasos, saneamiento y delindes. También gestionamos litis sobre terrenos registrados.</p>
        <a href='https://wa.me/8292218497' target="_blank">
          <button class="bg-blue-500 text-white py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg">+ Info</button>
        </a>
      </div>
      <div class="service-card bg-white bg-opacity-90 p-8 rounded-lg shadow-md hover:shadow-lg text-center">
        <h3 class="text-2xl font-bold mb-4">Gestión de Propiedades</h3>
        <p class="mb-4 text-justify">Especializados en compra y venta, administración de propiedades, cobro de alquileres y procesos de desalojo de inquilinos.</p>
        <a href='https://wa.me/8292218497' target="_blank">
          <button class="bg-blue-500 text-white py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg">+ Info</button>
        </a>
      </div>
    </div>
  </div>
    </section>


    <section id="sliderservices" class="relative flex flex-col w-screen  max-w-screen overflow-x-hidden pt-[9vh] lg:pt-[15vh] max-w-[100vw] bg-gradient-to-b from-blueheader via-white to-blueheader">
        <div class="slider">
          <div class="slide-track">
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" class="img">
            </div>
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" loading="lazy" class="img">
            </div>
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" loading="lazy" class="img">
            </div>
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" loading="lazy" class="img">
            </div>
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" loading="lazy" class="img">
            </div>
            <div class="slide" *ngFor="let icon of iconos">
              <img [src]="icon.imgSrc" loading="lazy" class="img">
            </div>
          </div>
        </div>
    </section>

    <section id="testimonios" class="py-12  text-white">
        <div class="container mx-auto">
          <h2 class="text-4xl font-bold text-center mb-12">Testimonios</h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div class="testimonial-card bg-white text-blueheader p-6 rounded-lg shadow-lg text-center">
              <div class="avatar mb-4 mx-auto">
                <img src="../../assets/person.png" loading="lazy" alt="Entidad Micropre SRL" class="rounded-full w-24 h-24 object-cover">
              </div>
              <h3 class="text-2xl font-bold mb-2">Entidad Micropre SRL</h3>
              <p class="text-sm text-gray-600 mb-4">Cliente</p>
              <p class="text-black">Satisfechos por la pronta recuperación de los valores apostados a COBREGA para el cobro, los cuales respondieron en pocos días, bajando dramáticamente los niveles de morosidad.</p>
            </div>
            <div class="testimonial-card bg-white text-blueheader p-6 rounded-lg shadow-lg text-center">
              <div class="avatar mb-4 mx-auto">
                <img src="../../assets/person.png" loading="lazy" alt="Moises García" class="rounded-full w-24 h-24 object-cover">
              </div>
              <h3 class="text-2xl font-bold mb-2">Moises García</h3>
              <p class="text-sm text-gray-600 mb-4">Cliente</p>
              <p class="text-black">Muy complacido con los resultados de la venta de un terreno, que le apoderó a COBREGA y él mismo se vendió en muy pocos días.</p>
            </div>
            <div class="testimonial-card bg-white text-blueheader p-6 rounded-lg shadow-lg text-center">
              <div class="avatar mb-4 mx-auto">
                <img src="../../assets/person.png" loading="lazy" alt="Plácida Corporan" class="rounded-full w-24 h-24 object-cover">
              </div>
              <h3 class="text-2xl font-bold mb-2">Plácida Corporan</h3>
              <p class="text-sm text-gray-600 mb-4">Cliente</p>
              <p class="text-black">Solicitó un préstamo personal y en menos de dos horas tenía el dinero en su cuenta, y a una tasa bien baja.</p>
            </div>
          </div>
        </div>
    </section>

    <section id="contact" class="relative w-screen h-auto bg-primary pt-[9vh] sm:max-w-[100vw] xs:px-1 sm:px-0">
    <div class="container mx-auto xs:-mt-5">
      <h2 class="text-4xl text-blueheader font-bold text-center mb-4">¿Por Qué Contactarnos?</h2>
      <p class="font-bold text-center mb-12 xs:text-justify  mx-auto px-4 leading-relaxed">
        "Nuestra empresa destaca en el área legal, específicamente en cobranzas, inmobiliaria y préstamos. Reducimos deudas rápidamente, facilitamos la venta y administración de propiedades, y ofrecemos préstamos personales, vehiculares e hipotecarios con tasas atractivas y privacidad."
      </p>
    </div>
  <div id="outcontact" class="flex relative w-full min-h-screen justify-center xs:p-3 items-center z-10 ">

    <div id="contactdiv" class="flex relative flex-col  space-y-6 md:flex-row md:space-x-6 md:space-y-0 xl:-mt-20 bg-blueheader w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
      <div class="flex flex-col space-y-8 justify-between">

        <div>
          <h1 class="font-bold text-4xl tracking-wide">Contáctanos</h1>
          <p class="pt-2 text-black text-md leading-relaxed font-bold"> Deje su mensaje y reciba la propuesta que ha estado esperando.</p>
          <p class="pt-2 font-bold text-xl text-myorange">Importante: Nunca le pediremos datos personales, tarjeta de credito/debito o de mas. Evite ser presa de los Cyber Atacantes.</p>
        </div>
        <div class="flex flex-col space-y-6">
          <div>
          <i class="fa-brands fa-whatsapp fa-bounce text-white text-xl"></i>
            <a href="https://wa.me/8295941972?text=Hola,%20estoy%20interesado%20en%20sus%20servicios"><span class="hover:text-hardblue"> 829-594-1972</span></a>
          </div>
          <div>
          <i class="fa-brands fa-whatsapp fa-bounce text-white text-xl"></i>
            <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios"><span class="hover:text-hardblue"> 829-221-8497</span></a>
          </div>
          <div>
            <i class="fa-solid fa-envelope text-myorange text-xl"></i>
            <a href="mailto:cobregasrl@gmail.com" target="_blank"></a><span class="ml-2 hover:text-hardblue cursor-pointer">cobregasrl&#64;gmail.com</span>
          </div>
          <div>
            <i class="fa-solid fa-business-time text-myorange text-xl"></i>
            <span> Lunes-Viernes 8:00AM - 5:00PM</span>
          </div>
        </div>
      </div>
      <div class="relative">
        <div class="absolute z-0 w-40 h-40 bg-hardblue/80 rounded-full -right-28 -top-28"></div>
        <div class="absolute z-0 w-40 h-40 bg-hardblue/80 rounded-full -left-28 -bottom-16"></div>
        <div class="relative z-10 bg-white rounded-xl shadow-lg p-8 text-hardblue md:w-80">
          <form [formGroup]="form" (ngSubmit)="send()" class="flex flex-col space-y-4">
            <div>
              <label for="name" class="text-sm text-hardblue">Nombre</label>
              <input formControlName="from_name"
                     id="from_name"
                     name="from_name"
                     type="text"

                     class="input ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-hardblue">
                     <span *ngIf="form.get('from_name')?.hasError('required') && form.get('from_name')?.touched" class="text-red-500">Este Campo es requerido</span>
            </div>
            <div>
              <label for="from_email" class="text-sm text-hardblue">Correo Electronico</label>
              <input formControlName="from_email"
                     id="from_email"
                     name="from_email"


                     class="input ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-hardblue">
                     <span *ngIf="form.get('from_email')?.hasError('required') && form.get('from_email')?.touched" class="text-red-500">Este Campo es requerido</span>
            </div>
            <div>
              <label for="subject" class="text-sm text-hardblue">Asunto</label>
              <input formControlName="subject"
                     id="subject"
                     name="subject"
                     type="text"

                     class="input ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-hardblue">
                     <span *ngIf="form.get('subject')?.hasError('required') && form.get('subject')?.touched" class="text-red-500">Este Campo es requerido</span>
            </div>
            <div>
              <label for="message" class="text-sm text-hardblue">Mensaje</label>
              <textarea formControlName="message"
                        id="message"
                        name="message"

                        rows="4"
                        class="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-hardblue"></textarea>
                        <span *ngIf="form.get('message')?.hasError('required') && form.get('message')?.touched" class="text-red-500">Este Campo es requerido</span>
            </div>
            <button [disabled]="sending" type="submit" class="inline-block self-end bg-hardblue text-white font-bold rounded-lg px-6 py-2 uppercase text-sm">
            {{sending ? 'Enviando Mensaje...' : 'Enviar Mensaje'}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
    </section>

<!-- <footer class="bg-hardblue text-white py-12 mt-10 px-6 sm:px-12 xl:px-24">
  <div class="grid grid-cols-1 md:grid-cols-5 gap-8">

    <div class="col-span-2 space-y-6">
      <h4 class="text-2xl font-bold text-myorange">Cobrega SRL</h4>
      <p class="text-lg">Santo Domingo Este, República Dominicana</p>
      <div class="space-y-4 text-lg">
        <div class="flex items-center">
          <i class="fa-solid fa-phone text-myorange text-2xl mr-3"></i>
          <strong> Teléfono 1: </strong><span class="ml-2">829-594-1972</span>
        </div>
        <div class="flex items-center">
          <i class="fa-solid fa-phone text-myorange text-2xl mr-3"></i>
          <strong> Teléfono 2: </strong><span class="ml-2 ">829-221-8497</span>
        </div>
        <div class="flex items-center">
          <i class="fa-solid fa-envelope text-myorange text-2xl mr-3"></i>
          <a href="mailto:cobregasrl@gmail.com" target="_blank"></a><strong> Email: </strong><span class="ml-2 hover:text-blueheader cursor-pointer">cobregasrl&#64;gmail.com</span>
        </div>
      </div>
    </div>


    <div class="col-span-1 space-y-6">
      <h4 class="text-2xl font-bold text-myorange">Servicios al alcance de todos</h4>
      <ul class="space-y-3 text-lg">
        <li>
          <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20de%20cobro" target="_blank" class="hover:underline transition duration-300 ">Cobros</a>
        </li>
        <li>
          <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20legales" target="_blank" class="hover:underline transition duration-300 ">Servicios Legales</a>
        </li>
        <li>
          <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20de%20prestamos" target="_blank" class="hover:underline transition duration-300">Préstamos</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="border-t border-gray-500 mt-10 pt-8 flex justify-between items-center text-sm text-gray-400">
    <div>&copy; 2024 Cobrega SRL - Todos los derechos reservados</div>
    <div class="text-orange-500">Desarrollado por <a href="https://backbonetechrd.com" target="_blank" class="hover:underline hover:text-orange-500">BackBoneTechrd</a></div>
  </div>
</footer> -->

<footer class="bg-hardblue text-white py-12 px-6 sm:px-12 xl:px-24">
  <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
    <!-- Información de contacto -->
    <div>
      <h4 class="text-xl font-bold">Cobrega SRL</h4>
      <p class="text-sm mt-2">Santo Domingo Este, República Dominicana</p>
      <ul class="space-y-2 mt-4">
        <li><i class="fas fa-phone-alt"></i> Teléfono: 829-594-1972</li>
        <li><i class="fas fa-phone-alt"></i> Teléfono: 829-221-8497</li>
        <li><i class="fas fa-envelope"></i> <a href="mailto:cobregasrl@gmail.com" target="_blank"></a><strong> Email: </strong><span class="ml-2 hover:text-blueheader cursor-pointer">cobregasrl&#64;gmail.com</span></li>
      </ul>
    </div>

    <!-- Servicios -->
    <div>
    <h4 class="text-xl font-bold ">Servicios al alcance de todos</h4>
      <ul class="space-y-3 text-lg">
        <li>
          <i class="fas fa-briefcase"></i> <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20de%20cobro" target="_blank" class="hover:underline transition duration-300 ml-1 ">Cobros</a>
        </li>
        <li>
          <i class="fas fa-balance-scale"></i> <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20legales" target="_blank" class="hover:underline transition duration-300 ml-1 ">Servicios Legales</a>
        </li>
        <li>
          <i class="fas fa-money-check-alt"></i>  <a href="https://wa.me/8292218497?text=Hola,%20estoy%20interesado%20en%20sus%20servicios%20de%20prestamos" target="_blank" class="hover:underline transition duration-300 ml-1">Préstamos</a>
        </li>
      </ul>
    </div>

    <!-- Créditos -->
    <div class="text-right">
      <h4 class="text-xl font-bold">Desarrollado por</h4>
      <p class="mt-2 text-sm">
        <a href="https://backbonetechrd.com" target="_blank" class="hover:underline text-md font-bold text-orange-500" style="font-family: 'Pacifico', cursive;">
          BackBoneTechrd
        </a>
      </p>
    </div>
  </div>
  <div class="footer-separator mt-6"></div>
  <div class="text-center mt-4 text-sm">
    © 2024 Cobrega SRL - Todos los derechos reservados
  </div>
</footer>

  `,
  styles: `#home img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }`

})
export class MainPage implements OnInit {

  sending: boolean = false;
  form: FormGroup;

  constructor(private fb:FormBuilder, private toastr: ToastrService){
    emailjs.init("06IsOkKUnviOpIvHu");
    this.form = this.fb.group({
      from_name: ['', Validators.required],
      from_email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required]

    })
  }

  async send(){
    if(!this.form.valid){
      this.toastr.error('Por Favor, complete todos los campos requeridos', 'Fallo al enviar', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
          closeButton: true,
          enableHtml: true
      })
      return;
    }

    this.sending = true
    try{

      const response = await emailjs.send("service_q3wogeb","template_at9jrop",{
      from_name: this.form.value.from_name,
      from_email: this.form.value.from_email,
      subject: this.form.value.subject,
      message: this.form.value.message,
      });
      if(response.status === 200){
        this.toastr.success('Su Mensaje ha sido exitosamente enviado, gracias!', 'Message send!!',{
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
          enableHtml: true
        });
        this.form.reset();
      }else{
        this.toastr.error('Fallo al enviar su mensaje. Status: ' + response.status, 'Error',{
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
          enableHtml: true
        });
      }
    }catch(error){
      this.toastr.error('Un Error ha ocurrido enviando el Email: ' + error, 'Error', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
          closeButton: true,
          enableHtml: true
      });
     } finally{
        this.sending=false
     }

  }
  ngOnInit() {
    const options = {
      strings: ["Gestión legal transparente", "Cobros eficaces", "Préstamos accesibles"],
      typeSpeed: 50,
      backSpeed: 25,
      loop: true
    };

    const typed = new Typed("#typed", options);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Home':
        this.scrollTo('home');
        break;
      case 'End':
        this.scrollTo('end');
        break;
      case 'PageUp':
        this.scrollPage('up');
        break;
      case 'PageDown':
        this.scrollPage('down');
        break;
      default:
        break;
    }
  }

  scrollTo(elementId: string) {
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
  }

  scrollPage(direction: 'up' | 'down') {
    const scrollAmount = window.innerHeight; // La cantidad de desplazamiento es la altura de la ventana
    if (direction === 'up') {
      window.scrollBy({ top: -scrollAmount, behavior: 'smooth' });
    } else if (direction === 'down') {
      window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  iconos =[
    {imgSrc: '../../assets/1i-removebg-preview.png'},
    {imgSrc: '../../assets/2-removebg-preview.png'},
    {imgSrc: '../../assets/3-removebg-preview.png'},
    {imgSrc: '../../assets/cobros-removebg-preview.png'},
    {imgSrc: '../../assets/prestamo-removebg-preview.png'},
  ]

}
